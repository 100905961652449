/* eslint-disable no-undef */
import '../css/_fonts.css'
import './bootstrap'
import '../css/app.css'
import '../css/_variables.css'
import '../css/custom.css'
import '../css/tinymce_custom.css'
import 'element-plus/dist/index.css'
import 'remixicon/fonts/remixicon.css'
import ElementPlus from 'element-plus'
import './ThirdParty/assets/index.css'

import vi from 'element-plus/dist/locale/vi.mjs'

import { createApp, h } from 'vue'
import { createInertiaApp } from '@inertiajs/inertia-vue3'
import { Link } from '@inertiajs/inertia-vue3'
import { InertiaProgress } from '@inertiajs/progress'
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers'
import { i18nVue } from 'laravel-vue-i18n'
import VueAxios from 'vue-axios'
import axios from '@/plugins/axios'
import store from './Store'
import VueKonva from 'vue-konva'

import './Plugin'
import './Utils'
import './Mixins'
import utilsMixin from '@/Plugin/FLowBuilder/mixins/utils.js'
import EventBus from './Utils/EventBus'
import Modal from '@/Plugin/Modal/modal-handle.js'
import ModalComponent from '@/Plugin/Modal/Modal.vue'
import VueClipboard from 'vue3-clipboard'
import TinyMceEditor from '@/Components/TinymceEditor.vue'

import mixinPermissions from './mixins/permission'

const appName = window.document.getElementsByTagName('title')[0]?.innerText || 'Laravel'

createInertiaApp({
    title: (title) => `${title} - ${appName}`,
    resolve: (name) => resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue')),
    setup({ el, app, props, plugin }) {
        const root = createApp({ render: () => h(app, props) })
            .use(plugin)
            //   .use(ZiggyVue, Ziggy)
            .mixin({
                methods: {
                    route,
                    ...mixinPermissions,
                },
            })
            .use(i18nVue, {
                lang: 'vi',
                resolve: async lang => {
                    const langs = import.meta.glob('../langs/*.json');
                    if (typeof langs[`../langs/${lang}.json`] != "undefined") {
                        return await langs[`../langs/${lang}.json`]();
                    }
                }
            })
            .use(ElementPlus, {
                locale: vi,
            })
            .use(VueAxios, axios)
            .use(store)
            .use(VueKonva)
            .component('Link', Link)
            .component('CsModal', ModalComponent)
            .mixin(utilsMixin)
            .component('tiny-mce-editor', TinyMceEditor)

        root.use(VueClipboard, {
            autoSetContainer: true,
            appendToBody: true,
        })

        root.config.globalProperties.$eventBus = EventBus
        root.config.globalProperties.$modal = Modal
        root.mount(el)
        return root
    },
}).then((res) => res)

InertiaProgress.init({ color: '#4B5563' })
