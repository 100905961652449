export default {
    radius: 15,
    padding: 20,
    innerPadding: 15,
    blockHeader: 32,
    blockWidth: 300,
    cardWidth: 260,
    statistical: {
        height: 50,
    },
    background: {
        message: '#dde4ea',
        action: '#fff7e2',
        delay: '#feb8ab',
        condition: '#baf9f2',
        random: '#e0d7fb',
        flow: '#e8fff2',
    },
    //
    flowBlockHeight: 160,
    randomBlockHeight: 160,
    delayBlockHeight: 200,
    condition: {
        itemHeight: 15,
        padding: 15,
    },
    //
    text: {
        fontSize: 12,
        padding: 30,
        button: 42,
    },
    OTN: {
        fontSize: 12,
        padding: 30,
        button: 42,
        labelHeight: 24,
    },
    userInput: {
        fontSize: 12,
        padding: 30,
        button: 42,
    },
    gallery: {
        horizontal: 200,
        square: 250,
        button: 42,
    },
    video: {
        height: 100,
        button: 42,
    },
    image: {
        height: 160,
        button: 42,
    },
    file: {
        height: 100,
    },
    audio: {
        height: 100,
        button: 42,
    },
    delay: {
        height: 100,
        button: 42,
    },
    form: {
        height: 160,
        button: 42,
        field: 85,
    },
};
