// import Vue from 'vue';
// import ClickOutside from 'vue-click-outside';
// import PerfectScrollbar from 'vue-perfect-scrollbar';

// Vue.directive('click-outside', ClickOutside);
// Vue.component('PerfectScrollbar', PerfectScrollbar);

import { createApp } from 'vue';
import ClickOutside from 'vue-click-outside';
import PerfectScrollbar from 'vue-perfect-scrollbar';

const app = createApp({});
app.directive('click-outside', ClickOutside);
app.component('PerfectScrollbar', PerfectScrollbar);
app.mount('#app');