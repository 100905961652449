// import LoadingComponent from './loading.vue';

// const loading = {
//     install(Vue) {
//         this.event = new Vue();
//         let self = this;
//         Vue.prototype.$spinner = {
//             show() {
//                 self.event.$emit('show');
//             },
//             hide() {
//                 self.event.$emit('hide');
//             },
//         };
//         Vue.component('CsLoading', LoadingComponent);
//     },
// };
// export default loading;

import { createApp, reactive } from 'vue';
import LoadingComponent from './loading.vue';

const loading = {
    install(app) {
        const state = reactive({
            isShowing: false
        });

        app.config.globalProperties.$spinner = {
            show() {
                state.isShowing = true;
            },
            hide() {
                state.isShowing = false;
            },
        };
        
        app.component('CsLoading', LoadingComponent);

        app.provide('loadingState', state);
    }
};

export default loading;
