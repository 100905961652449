const defaultState = {
    currentBot: {},
};

const actions = {};

const mutations = {
    SET_CURRENT_BOT(state, value) {
        state.currentBot = { ...value.bot };
    },
};

const getters = {};
export default {
    namespaced: true,
    state: defaultState,
    getters,
    actions,
    mutations,
};
