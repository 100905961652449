const objectToQueryString = function (obj) {
    return Object.keys(obj)
        .filter((index) => obj[index] && obj[index].toString().trim() != '')
        .map((key) => {
            return key + '=' + obj[key];
        })
        .join('&');
};

const paramFromUrl = function () {
    const url = window.location.href;
    var vars = {};
    url.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (m, key, value) {
        vars[key] = value;
    });
    return vars;
};

const debounce = function (func, wait) {
    var timeout;
    return function () {
        var context = this;
        var args = arguments;
        var executeFunction = function () {
            func.apply(context, args);
        };
        clearTimeout(timeout);
        timeout = setTimeout(executeFunction, wait);
    };
};

export { objectToQueryString, paramFromUrl, debounce };
