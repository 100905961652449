import FlowModule from './Modules/flow'
import HomeModule from './Modules/home'
import BotModule from './Modules/bot'
import FormModule from './Modules/form'

import { createStore } from 'vuex'

// const moduleB = {}

// const store = createStore({
//   modules: {
//     b: moduleB,
//   },
// })

const store = createStore({
  modules: {
    flow: FlowModule,
    home: HomeModule,
    bot: BotModule,
    form: FormModule,
  },
})


export default store
