import { paramFromUrl } from '../../Helper/index';

const route = {
    install(app) {
        app.config.globalProperties.$route = {
            query: paramFromUrl(),
        };
    },
};

export default route;
