// import Vue from 'vue';

export const set = (key, defaultValue) => (state, value) => (state[key] = value || defaultValue);

export const toggle = (key) => (state) => (state[key] = !state[key]);

export const add =
    (property) =>
    (state, { newEl, toTopOfList = true, perPage = 50, index }) => {
        if (Number.isInteger(index) && index > -1) {
            state[property].splice(index, 0, newEl);
            return;
        }
        if (toTopOfList) state[property].unshift(newEl);
        else state[property].push(newEl);
        if (state[property].length > perPage) state[property].splice(perPage, 1);
    };

export const remove =
    (property) =>
    (state, { id, index, value, compare = 'id' }) => {
        if (!Number.isInteger(index) && id !== undefined) {
            index = state[property].findIndex((e) => e.id === id);
        } else if (!Number.isInteger(index) && compare) {
            if (typeof compare === 'string') compare = [compare];
            index = state[property].findIndex((e) => compare.every((key) => value[key] === e[key]));
        }
        if (index >= 0) {
            state[property].splice(index, 1);
        }
    };

export const removeMany =
    (property) =>
    (state, { indexs = [], data = {}, compare = ['id'], ids = [] }) => {
        compare = typeof compare === 'string' ? [compare] : compare;
        state[property] = state[property].filter(
            (item, index) =>
                !(indexs.includes(index) || compare.every((c) => data[c] === item[c]) || ids.includes(item.id))
        );
    };

export const update =
    (key) =>
    (state, { value, index, compare = 'id' }) => {
        if (!Array.isArray(state[key])) {
            return Object.assign(state[key], value);
        }
        if (!Number.isInteger(index)) {
            if (typeof compare === 'string') compare = [compare];
            index = state[key].findIndex((e) => compare.every((key) => value[key] === e[key]));
        }
        if (index >= 0) {
            state[key].splice(index, 1, value);
        }
    };

export const updatePagination = (listName) => (state, step) => {
    const pagination = state[listName + 'Pagination'] || state.pagination;
    pagination.total = Math.max(pagination.total + step, 0);
    pagination.totalPages = Math.ceil(pagination.total / pagination.pageSize);
    // const pageStep = Math.floor(
    //   (state[listName].length + step) / pagination.pageSize - 1
    // );
    // pagination.page = Math.min(
    //   Math.max(pagination.page + pageStep, 0),
    //   pagination.totalPages
    // );
};

export const setProperty =
    (keyState) =>
    (state, { key, value, index = -1 }) => {
        try {
            if (index >= 0) state[keyState][index][key] = value;
            else state[keyState][key] = value;
        } catch (error) {
            return new Error(error);
        }
    };

export const setPropertyNestedObject =
    () =>
    (state, { obj, property }) => {
        const { key, value } = property;
        obj[key] = value;
    };

export const removeByIds = (property) => (state, ids) => {
    state[property] = state[property].filter((_e) => !ids.includes(_e.id));
};

/**
 *
 * @param {string} property name of list properties
 */
export const listMutations = (property, name) => {
    return {
        /**
         *
         * @param {object} state vuex state
         * @param {any}  item value in list
         */
        ['UPDATE_' + name.toUpperCase()](state, { item, compare }) {
            if (!compare) return;
            if (typeof compare === 'string') compare = [compare];
            const index = state[property].findIndex((e) => compare.every((key) => item[key] === e[key]));
            if (index >= 0) {
                const clone = state[property][index];
                Object.assign(clone, item);
                state[property][index] = clone;
            }
        },
        /**
         *
         * @param {object} state vuex state
         * @param {any}  item value in list
         */
        ['SET_' + name.toUpperCase()](state, { item, compare }) {
            if (!compare) return;
            if (typeof compare === 'string') compare = [compare];
            const index = state[property].findIndex((e) => compare.every((key) => item[key] === e[key]));
            if (index >= 0) {
                state[property][index] = item;
            }
        },
        ['SET_MANY_' + name.toUpperCase()](state, { items = [], compare }) {
            if (compare) {
                if (typeof compare === 'string') compare = [compare];
                items.forEach((item) => {
                    const index = state[property].findIndex((e) => compare.every((key) => item[key] === e[key]));
                    if (index >= 0) {
                        state[property][index] = item;
                    }
                });
            } else {
                items.forEach((item) => {
                    const { index, value } = item;
                    state[property][index] = value;
                });
            }
        },
        ['SET_LIST_' + name.toUpperCase()](state, value) {
            state[property] = value;
        },
        ['ADD_' + name.toUpperCase()](state, { item, index }) {
            if (!index) {
                state[property].push(item);
            } else {
                state[property][index] = item;
            }
        },
        ['ADD_MANY' + name.toUpperCase()](state, items) {
            items.forEach((item) => {
                const { index, value } = item;
                if (!index) {
                    state[property].push(value);
                } else {
                    // Vue.set(state[property], index, value);
                    state[property][index] = item;
                }
            });
        },
        ['REMOVE_' + name.toUpperCase()](state, { item, compare, index }) {
            if (index) {
                state[property].splice(index, 1, item);
            } else {
                if (!compare) return;
                if (typeof compare === 'string') compare = [compare];
                const index = state[property].findIndex((e) => compare.every((key) => item[key] === e[key]));
                if (index >= 0) {
                    state[property].splice(index, 1);
                }
            }
        },
        /**
         *
         * @param {*} state vuex state
         * @param {[Number]} indexs list index of item want delete
         */
        ['REMOVE_MANY' + name.toUpperCase()](state, indexs) {
            state[property] = state[property].filter((e, i) => !indexs.includes(i));
        },
    };
};
