//define global mixin
// import Vue from 'vue';


import GetImage from './GetImage';
import InertiaCallback from './Inertia';

import { createApp } from 'vue'

const app = createApp({})


app.mixin(GetImage);
app.mixin(InertiaCallback);
// Vue.mixin(GetImage);
// Vue.mixin(InertiaCallback);
