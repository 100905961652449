export default {
    getPermissions() {
        return this.$page?.props?.auth?.permissions?.map((permission) => {
            return permission.name
        });
    },
    checkPermissions(permissions) {
        if (!permissions || !permissions.length) {
            return true
        }
        let getPermissions = this.$page?.props?.auth?.permissions?.map((permission) => {
            return permission.name
        })
        return permissions
            .map((permission) => {
                return permission
            })
            .some((permission) => {
                if (getPermissions.includes(permission)) {
                    return true
                }
                return false
            })
    },
    checkPermission(permission) {
        let getPermissions = this.$page?.props?.auth?.permissions?.map((permission) => {
            return permission.name
        })
        if (getPermissions.includes(permission)) {
            return true
        }
        return false
    },
    isAdministrator() {
        this.$page?.props?.auth?.role?.includes('master')
    }
}
