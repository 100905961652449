// import Vue from 'vue';
// import Modal from './Modal/modal-handle';
// import Spinner from './Loading/loading-handle';
// import FakeRoute from './Route/handle-route';

// //plugin flow builer
// import './FLowBuilder/extras';
// import './FLowBuilder/konva';
// import './FLowBuilder/text-highlight';
// import './FLowBuilder/mixins/utils';
// import './capture-request';

// Vue.use(Modal);
// Vue.use(Spinner);
// Vue.use(FakeRoute);


import { createApp } from 'vue';


import Modal from './Modal/modal-handle';
import Spinner from './Loading/loading-handle';
import FakeRoute from './Route/handle-route';

//plugin flow builer
import './FLowBuilder/extras';
import './FLowBuilder/konva';
import './FLowBuilder/text-highlight';
import './FLowBuilder/mixins/utils';
import './capture-request';



const app = createApp({});
app.use(Modal);
app.use(Spinner);
app.use(FakeRoute);
app.mount('#app');