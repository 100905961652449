import { set, setPropertyNestedObject } from '@/Utils/vuex';

export const defaultState = {
    api: {
        location: 'api/location',
    },
    listLocation: [],
    navbarSelected: [],
    layout: { default: { class: [] } },
    sidebar: {
        opened: true,
    },
    meta: {},
    responsive: '',
    appCode: 'chatbot',
};

export const getters = {};

export const mutations = {
    SET_LAYOUT(state, update) {
        Object.assign(state.layout, update);
    },
    SET_RESPONSIVE: set('responsive', ''),
    TOGGLE_SIDE_BAR(state, value) {
        state.sidebar.opened = value !== undefined ? value : !state.sidebar.opened;
    },
    SET_META: set('meta', {}),
    SET_PROPERTY_META(state, { name, value }) {
        state.meta[name] = value;
    },
    SET_NAVBAR_SELECTED: set('navbarSelected', []),
    CHANGE_PROPERTY_NESTED_OBJECT: setPropertyNestedObject(),

    SET_LIST_LOCATION: set('listLocation', []),
};

export const actions = {
    async nuxtServerInit({ dispatch }) {
        try {
            await dispatch('getListLocation');
        } catch (err) {
            console.log('nuxtServerInit', err);
        }
    },

    async getListLocation({ state, commit }) {
        const { location } = state.api;
        try {
            const { data } = await axios.get(location);
            const find = ['Thành phố Đà Nẵng', 'Thành phố Hải Phòng', 'Thành Phố Hồ Chí Minh', 'Thành phố Hà Nội'];

            find.forEach((text) => {
                const index = data.findIndex((d) => d.name === text);
                const t = data.splice(index, 1);
                data.unshift(t[0]);
            });

            commit('SET_LIST_LOCATION', data);
        } catch (err) {
            console.log('getListLocation: ', err);
        }
    },
};

export default {
    namespaced: true,
    state: defaultState,
    getters,
    actions,
    mutations,
};
