import { ElLoading } from 'element-plus'
export default {
    data() {
        return {
            loadingService: null
        }
    },
    methods: {
        onBeforeLoad() {
            this.loadingService && (this.loadingService = ElLoading.service({ fullscreen: true }))
        },
        onFinishLoad() {
            this.loadingService && (this.loadingService.close())
        }
    }
}