<template>
  <Editor ref="tinyMceEditor" v-model="content" :api-key="tinyMceKey" :init="tinyMceInit" />
</template>

<script>
import Editor from '@tinymce/tinymce-vue'
import axios from '@/plugins/axios'

export default {
  components: {
    Editor,
  },
  data() {
    return {
      content: null,
      tinyMceKey: 'il3fud0tvml1cv9j5yzwqk2ci5a4jjr8mx8ng0q9dy5jcu2i',
      tinyMceInit: {
        width: '100%',
        height: 420,
        menubar: false,
        plugins: 'fullscreen autolink lists link image preview wordcount code table autosave searchreplace charmap quickbars',
        toolbar: 'fullscreen | undo redo restoredraft | formatselect | bold italic forecolor backcolor fontsize  | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent charmap | image| link | table | searchreplace code preview',
        fontsize_formats: "8pt 10pt 12pt 14pt 18pt 24pt 36pt",
        file_picker_types: 'image',
        file_picker_callback: (callback) => {
          const input = document.createElement('input')
          input.setAttribute('type', 'file')
          input.setAttribute('accept', 'image/*')
          input.addEventListener('change', (e) => {
            const file = e.target.files[0]
            const formData = new FormData()
            formData.append('file', file)
            axios
              .post('upload-image', formData)
              .then(({ data, status }) => {
                if (status === 200) {
                  callback(data?.data?.fileUrl, { title: data?.data?.fileName })
                }
              })
              .catch(() => {
                this.$message({ message: this.$t('Có lỗi trong quá trình upload ảnh.'), type: 'error' })
              })
          })

          input.click()
        },
        image_advtab: true,
        image_title: true,
        image_caption: true,
        image_description: true,
        fullscreen_native: true,
        branding: false,
        quickbars_image_toolbar: false,
        quickbars_insert_toolbar: "quicktable | hr pagebreak"
      },
    }
  },
}
</script>

<style scoped>
.tox-statusbar__branding {
  display: none;
}
</style>
