<template>
    <div v-loading.fullscreen.lock="isVisibale"></div>
</template>

<script>
import spinner from './loading-handle';

export default {
    data() {
        return {
            isVisibale: false,
        };
    },
    mounted() {
        let self = this;
        spinner.event.$on('show', function () {
            self.isVisibale = true;
        });
        spinner.event.$on('hide', function () {
            self.isVisibale = false;
        });
    },
};
</script>
<style lang="css" scped>
.el-loading-spinner {
    display: flex;
    justify-content: center;
}
</style>
