const EventBus = {
    subscribe(event_name, callback) {
        document.addEventListener(event_name, function (e) {
            callback(e.detail);
        });
    },
    publish(event_name, params) {
        var event = new CustomEvent(event_name, {
            detail: params,
        });
        document.dispatchEvent(event);
    },
};

export default EventBus;