import { cloneDeep } from '@/Utils/lodash';

const utilsMixin = {
    mounted() {
        window.onresize = this.getResponvie;
        window.onload = this.getResponvie;
    },
    methods: {
        /**
         *
         * @param {string} text
         * @param {Number} length
         */
        textElipsis(text = '', length) {
            if (text === null) return '';
            const sufix = text.length > length ? '...' : '';
            return text.substring(0, length) + sufix;
        },
        getTimeUnitName(unit) {
            let name = '';
            if (/second/.test(unit)) {
                name = 'giây';
            } else if (/minute/.test(unit)) {
                name = 'phút';
            } else if (/hour/.test(unit)) {
                name = 'giờ';
            } else if (/day/.test(unit)) {
                name = 'ngày';
            } else if (/week/.test(unit)) {
                name = 'tuần';
            } else if (/month/.test(unit)) {
                name = 'tháng';
            } else if (/year/.test(unit)) {
                name = 'năm';
            }
            return name;
        },
        getResponvie() {
            let rp = '';
            rp = window.innerWidth < 786 ? 'xs' : rp;
            rp = window.innerWidth >= 786 ? 'sm' : rp;
            rp = window.innerWidth >= 992 ? 'md' : rp;
            rp = window.innerWidth >= 1200 ? 'lg' : rp;
            rp = window.innerWidth >= 1920 ? 'xl' : rp;
            if (this.$store) this.$store.commit('home/SET_RESPONSIVE', rp);
        },
        trimText(text, length = 32) {
            if (text && text.length > length) return text.slice(0, length) + '...';
            else return text;
        },
        peek(arr = [], index) {
            index = index || arr.length - 1;
            return arr[index];
        },
        useless() {},
        inRect(point, rect) {
            return point.x >= rect.left && point.x <= rect.right && point.y >= rect.top && point.y <= rect.bottom;
        },
        /**
         *
         * @param {Object} obj src object
         * @param {[String]} keys listKey want copy
         * @param {Array|any} except except key value
         */
        copyObj(obj, keys, except) {
            if (except && !Array.isArray(except)) except = [except];
            if (keys && Array.isArray(keys)) {
                const result = {};
                keys.forEach((key) => {
                    if (!except.includes(obj[key])) result[key] = obj[key];
                });
                return result;
            } else return cloneDeep(obj);
        },

        avatarOnError(e) {
            e.target.src = '/default/avatar.jpg';
        },
        imgOnError(e) {
            e.target.src = '/default/image.png';
        },
        timeFromNow(dateStr) {
            if (!dateStr) {
                return;
            }
            if (typeof dateStr === 'string') {
                dateStr = dateStr.trim();
                dateStr = dateStr.replace(/\.\d\d\d+/, ''); // remove the milliseconds
                dateStr = dateStr.replace(/-/, '/').replace(/-/, '/'); // substitute - with /
                dateStr = dateStr.replace(/T/, ' ').replace(/Z/, ' UTC'); // remove T and substitute Z with UTC
                dateStr = dateStr.replace(/([+-]\d\d):?(\d\d)/, ' $1$2'); // +08:00 -> +0800
            }

            let parsedDate = dateStr;
            if (!(dateStr instanceof Date)) {
                parsedDate = new Date(dateStr);
            }
            const relativeTo = arguments.length > 1 ? arguments[1] : new Date(); // defines relative to what ..default is now
            let delta = parseInt((relativeTo.getTime() - parsedDate) / 1000);
            delta = delta < 2 ? 2 : delta;
            // let r = this.$t('frontend.common.just_now')
            let r = 'vừa xong';
            if (delta < 60) {
                // r = delta + ' ' + this.$t('frontend.common.last_second')
                r = delta + ' ' + 'giây trước';
            } else if (delta < 60 * 60) {
                // r =
                //   parseInt(delta / 60, 10).toString() +
                //   ' ' +
                //   this.$t('frontend.common.last_minute')
                r = parseInt(delta / 60, 10).toString() + ' ' + 'phút trước';
            } else if (delta < 24 * 60 * 60) {
                r = '' + parseInt(delta / 3600, 10).toString() + ' ' + 'giờ trước';
            } else if (delta < 2 * 24 * 60 * 60) {
                // r = this.$t('frontend.common.yesterday')
                r = 'hôm qua';
            } else if (delta < 30 * 24 * 60 * 60) {
                r = parseInt(delta / 86400, 10).toString() + ' ' + 'ngày trước';
                // this.$t('frontend.common.last_day')
            } else if (delta < 365 * 24 * 60 * 60) {
                r = parseInt(delta / (30 * 86400), 10).toString() + ' ' + 'tháng trước';
                // this.$t('frontend.common.last_month')
            } else {
                r = parseInt(delta / (365 * 86400), 10).toString() + ' ' + 'năm trước';
                // this.$t('frontend.common.last_year')
            }
            return r;
        },
        formatNumber(nStr) {
            return nStr ? (+nStr).toLocaleString('vi-VN') : 0;
        },
        // format number with character
        formatTime(date, format = 'dd/MM/yyyy', utc) {
            if (!date) {
                return '';
            }
            if (!(date instanceof Date)) {
                date = new Date(date);
            }
            const MMMM = [
                '\x00',
                'January',
                'February',
                'March',
                'April',
                'May',
                'June',
                'July',
                'August',
                'September',
                'October',
                'November',
                'December',
            ];
            const MMM = ['\x01', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
            const dddd = ['\x02', 'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
            const ddd = ['\x03', 'Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

            function ii(i, len) {
                let s = i + '';
                len = len || 2;
                while (s.length < len) s = '0' + s;
                return s;
            }

            const y = utc ? date.getUTCFullYear() : date.getFullYear();
            format = format.replace(/(^|[^\\])yyyy+/g, '$1' + y);
            format = format.replace(/(^|[^\\])yy/g, '$1' + y.toString().substr(2, 2));
            format = format.replace(/(^|[^\\])y/g, '$1' + y);

            const M = (utc ? date.getUTCMonth() : date.getMonth()) + 1;
            format = format.replace(/(^|[^\\])MMMM+/g, '$1' + MMMM[0]);
            format = format.replace(/(^|[^\\])MMM/g, '$1' + MMM[0]);
            format = format.replace(/(^|[^\\])MM/g, '$1' + ii(M));
            format = format.replace(/(^|[^\\])M/g, '$1' + M);

            const d = utc ? date.getUTCDate() : date.getDate();
            format = format.replace(/(^|[^\\])dddd+/g, '$1' + dddd[0]);
            format = format.replace(/(^|[^\\])ddd/g, '$1' + ddd[0]);
            format = format.replace(/(^|[^\\])dd/g, '$1' + ii(d));
            format = format.replace(/(^|[^\\])d/g, '$1' + d);

            const H = utc ? date.getUTCHours() : date.getHours();
            format = format.replace(/(^|[^\\])HH+/g, '$1' + ii(H));
            format = format.replace(/(^|[^\\])H/g, '$1' + H);

            const h = H > 12 ? H - 12 : H === 0 ? 12 : H;
            format = format.replace(/(^|[^\\])hh+/g, '$1' + ii(h));
            format = format.replace(/(^|[^\\])h/g, '$1' + h);

            const m = utc ? date.getUTCMinutes() : date.getMinutes();
            format = format.replace(/(^|[^\\])mm+/g, '$1' + ii(m));
            format = format.replace(/(^|[^\\])m/g, '$1' + m);

            const s = utc ? date.getUTCSeconds() : date.getSeconds();
            format = format.replace(/(^|[^\\])ss+/g, '$1' + ii(s));
            format = format.replace(/(^|[^\\])s/g, '$1' + s);

            let f = utc ? date.getUTCMilliseconds() : date.getMilliseconds();
            format = format.replace(/(^|[^\\])fff+/g, '$1' + ii(f, 3));
            f = Math.round(f / 10);
            format = format.replace(/(^|[^\\])ff/g, '$1' + ii(f));
            f = Math.round(f / 10);
            format = format.replace(/(^|[^\\])f/g, '$1' + f);

            const T = H < 12 ? 'AM' : 'PM';
            format = format.replace(/(^|[^\\])TT+/g, '$1' + T);
            format = format.replace(/(^|[^\\])T/g, '$1' + T.charAt(0));

            const t = T.toLowerCase();
            format = format.replace(/(^|[^\\])tt+/g, '$1' + t);
            format = format.replace(/(^|[^\\])t/g, '$1' + t.charAt(0));

            let tz = -date.getTimezoneOffset();
            let K = utc || !tz ? 'Z' : tz > 0 ? '+' : '-';
            if (!utc) {
                tz = Math.abs(tz);
                const tzHrs = Math.floor(tz / 60);
                const tzMin = tz % 60;
                K += ii(tzHrs) + ':' + ii(tzMin);
            }
            format = format.replace(/(^|[^\\])K/g, '$1' + K);

            const day = (utc ? date.getUTCDay() : date.getDay()) + 1;
            format = format.replace(new RegExp(dddd[0], 'g'), dddd[day]);
            format = format.replace(new RegExp(ddd[0], 'g'), ddd[day]);

            format = format.replace(new RegExp(MMMM[0], 'g'), MMMM[M]);
            format = format.replace(new RegExp(MMM[0], 'g'), MMM[M]);

            format = format.replace(/\\(.)/g, '$1');

            return format;
        },
        formatDate(date, format = 'dd/MM/yyyy') {
            if (date) {
                date = new Date(date);
                const formatter = {
                    d: (date) => date.getDate(),
                    dd: (date) => date.getDate().toString().padStart(2, '0'),
                    h: (date) => date.getHours().toString().padStart(2, '0'),
                    mm: (date) => date.getMinutes().toString().padStart(2, '0'),
                    ss: (date) => date.getSeconds().toString().padStart(2, '0'),
                    MM: (date) => (date.getMonth() + 1).toString().padStart(2, '0'),
                    Y: (date) => date.getFullYear(),
                    yyyy: (date) => date.getFullYear().toString().padStart(2, '0'),
                };
                // sort keys by length, then value
                const keys = Object.keys(formatter)
                    .sort((a, b) => {
                        if (a.length > b.length) return -1;
                        if (a.length < b.length) return 1;
                        if (a > b) return -1;
                        if (a < b) return 1;
                        return 0;
                    })
                    .join('|');

                return format.replace(new RegExp(`${keys}`, 'g'), (m) => {
                    return formatter[m](date);
                });
            } else {
                return null;
            }
        },
        slug(str, symbol = '-') {
            return str
                .toLowerCase()
                .replace(/(à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ)/g, 'a')
                .replace(/(è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ)/g, 'e')
                .replace(/(ì|í|ị|ỉ|ĩ)/g, 'i')
                .replace(/(ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ)/g, 'o')
                .replace(/(ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ)/g, 'u')
                .replace(/(ỳ|ý|ỵ|ỷ|ỹ)/g, 'y')
                .replace(/(đ)/g, 'd')
                .replace(/([^0-9a-z-\s])/g, '')
                .replace(/(\s+)/g, symbol)
                .replace(/^-+/g, '')
                .replace(/-+$/g, '');
        },
        validatePhoneNumber(str) {
            const patt = /(?:(0|\+84))([0-9]{2,4})[-. ]?(\d{3})[-. ]?(\d{4}|\d{3})\b/gim;
            return patt.test(str);
        },
        validateEmail(str) {
            const patt = /[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\b/gim;
            return patt.test(str);
        },
        convertNumberToVietnamese(number) {
            const first = (r, n, arr) => {
                let o = '';
                const a = Math.floor(r / 10);
                const e = r % 10;
                return (
                    a > 1
                        ? ((o = ' ' + arr[a] + ' Mươi'), e === 1 && (o += ' Mốt'))
                        : a === 1
                        ? ((o = ' Mười'), e === 1 && (o += ' Một'))
                        : n && e > 0 && (o = ' Lẻ'),
                    e === 5 && a >= 1
                        ? (o += ' Lăm')
                        : e === 4 && a >= 1
                        ? (o += ' Tư')
                        : (e > 1 || (e === 1 && a === 0)) && (o += ' ' + arr[e]),
                    o
                );
            };
            const second = (n, o, arr) => {
                let a = '';
                const e = Math.floor(n / 100);
                n = n % 100;
                return (
                    o || e > 0 ? ((a = ' ' + arr[e] + ' Trăm'), (a += first(n, !0, arr))) : (a = first(n, !1, arr)), a
                );
            };
            const third = (t, r, arr) => {
                let o = '';
                const a = Math.floor(t / 1e6);
                t = t % 1e6;
                if (a > 0) {
                    o = second(a, r, arr) + ' Triệu';
                    r = !0;
                }
                const e = Math.floor(t / 1e3);
                t = t % 1e3;
                return e > 0 && ((o += second(e, r, arr) + ' Ngàn'), (r = !0)), t > 0 && (o += second(t, r, arr)), o;
            };

            if (isNaN(number)) {
                return number;
            }
            const arr = ['Không', 'Một', 'Hai', 'Ba', 'Bốn', 'Năm', 'Sáu', 'Bảy', 'Tám', 'Chín'];
            if (number === 0) return arr[0];
            let n = '';
            let a = '';
            do {
                const ty = number % 1e9;
                number = Math.floor(number / 1e9);
                n = number > 0 ? third(ty, !0, arr) + a + n : third(ty, !1, arr) + a + n;
                a = ' Tỷ';
            } while (number > 0);
            return n.trim();
        },
    },
}

export default utilsMixin;