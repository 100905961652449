import emitter from 'tiny-emitter/instance'
const Modal = {
  open({ name }) {
    emitter.emit('open-modal', { name })
  },
  close({ name, onDone }) {
    emitter.emit('close-modal', { name, onDone })
  },
  toggle({ name }) {
    emitter.emit('toggle-modal', { name })
  },
}

export default Modal
