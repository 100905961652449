// import Vue from 'vue';
// import TextHighlight from 'vue-text-highlight';

// Vue.component('TextHighlight', TextHighlight);

import { createApp } from 'vue';
import TextHighlight from 'vue-highlight-words';

const app = createApp({});
app.component('TextHighlight', TextHighlight);
app.mount('#app');
