var send = window.XMLHttpRequest.prototype.send;

function sendReplacement() {
    if (this.onreadystatechange) {
        this._onreadystatechange = this.onreadystatechange;
    }
    this.onreadystatechange = onReadyStateChangeReplacement;
    return send.apply(this, arguments);
}
function onReadyStateChangeReplacement() {
    abortUnAuthenticate(arguments);
    if (this._onreadystatechange) {
        return this._onreadystatechange.apply(this, arguments);
    }
}
window.XMLHttpRequest.prototype.send = sendReplacement;

const abortUnAuthenticate = function (arg) {
    if (arg.length > 0 && arg[0] instanceof Event) {
        // if ([400, 403, 404].includes(arg[0]?.currentTarget?.status) ) {
        //     window.location.href = `/error/${arg[0]?.currentTarget?.status}`
        // }
    }
};
