import konvaConfig from '@/Config/flow-canvas';
// import Vue from 'vue';
import { set, add, update, removeByIds, listMutations } from '@/Utils/vuex';
import { cloneDeep, uniq } from '@/Utils/lodash';
import axios from '@/Utils/axios';

const defaultState = {
    api: {
        flow: '/flows',
        block: '/blocks',
        card: '/cards',
    },
    filter: {},
    listEvent: [],
    konva: konvaConfig,
    highLight: {
        cardId: 0,
        blockId: 0,
    },
    canvas: {
        scale: 1,
        scaleBy: 1.1,
        dragBlock: null,
        hoverBlock: null,
        hoverPort: {},
    },
    dragBlockPosition: null,
    listMenuItem: [
        {
            id: 'flow',
            name: ' Quản lý kịch bản',
            icon: 'fas fa-folder-open',
            children: [],
        },
        {
            id: 'optin',
            name: 'Opt-In Messages',
            icon: 'far fa-magnet',
        },
        {
            id: 'keyword',
            name: 'Trả lời theo từ khóa',
            icon: 'fas fa-spell-check',
        },
        {
            id: 'sequence',
            name: 'Gửi tin nhắn hàng loạt',
            icon: 'far fa-arrow-alt-right',
        },
        {
            id: 'welcome',
            name: 'Văn bản chào mừng',
            icon: 'far fa-hand-spock',
        },
        {
            id: 'default',
            name: 'Trả lời mặc định',
            icon: 'fal fa-reply-all',
        },
        {
            id: 'menu',
            name: 'Nội dung menu chính',
            icon: 'far fa-bars',
        },
        {
            id: 'trash',
            name: 'Thùng rác',
            icon: 'far fa-trash-alt',
        },
    ],
    breadCrumb: [],
    currentOption: '',
    dragBlockId: 0,
    listFlow: [],
    currentFlow: null,
    flowSidebar: false,
    // Block
    currentFolder: null,
    listBlock: [],
    selectedBlock: null,
    selectedCard: null,
    builder: {
        mode: 'flow', // basic
        type: 'publish', // publish
    },
    connect: {
        from: null,
        to: null,
    },
    // End block
    paginationFlow: {
        page: 1,
        pageSize: 20,
        total: 0,
        totalPages: 0,
    },
    listFlowFolder: [],
    paginationFlowFolder: {
        page: 1,
        pageSize: 20,
        total: 0,
        totalPages: 0,
    },
    disableCards: [],
    penddingSaveCard: false,
};
const mutations = {
    // Disable Card
    ADD_DISABLE_CARDS(state, value) {
        if (!Array.isArray(value)) {
            value = [value];
        }
        state.disableCards.push(...value);
        state.disableCards = uniq(state.disableCards);
    },
    REMOVE_DISABLE_CARDS(state, value) {
        if (!Array.isArray(value)) {
            value = [value];
        }
        state.disableCards = state.disableCards.filter((e) => !value.includes(e));
    },
    // Disable Select Message Tag

    SET_HIGH_LIGHT(state, value) {
        Object.assign(state.highLight, value);
    },
    SET_PENDING_SAVE_CARD(state, value) {
        state.penddingSaveCard = value;
    },
    SET_DRAG_BLOCK_POSITION(state, pos) {
        state.dragBlockPosition = pos;
    },
    SET_CONNECT(state, value) {
        Object.assign(state.connect, value);
    },
    SET_BUILDER(state, update) {
        Object.assign(state.builder, update);
    },
    SET_CANVAS(state, update) {
        Object.assign(state.canvas, update);
    },
    SET_FLOW_SIDEBAR: set('flowSidebar', false),
    ...listMutations('listBlock', 'BLOCK'),
    SET_LIST_BLOCK(state, data) {
        state.listBlock.splice(0);
        state.listBlock.push(...data);
    },
    SET_LIST_BLOCK_DRAFT(state, data) {
        state.listBlock.splice(0);
        state.listBlock.push(...data);
    },
    // filter
    SET_FILTER: set('filter', {}),
    CLEAR_FILTER(state) {
        state.filter = {};
    },
    ADD_FILTER(state, value) {
        Object.entries(value).forEach(([key, val]) => {
            state.filter[key] = val;
        });
    },
    /**
     *
     * @param {*} keys List of key you want remove
     */
    REMOVE_FILTER(state, keys) {
        keys.forEach((key) => {
            delete state.filter[key];
        });
    },
    // flow
    SET_LIST_FLOW: set('listFlow', []),
    SET_FLOW_BUILDER_TYPE(state, type) {
        if (!type) {
            state.flowBuilderType = ['flow', 'basic'].find((e) => e !== state.flowBuilderType);
        } else {
            state.flowBuilderType = type;
        }
    },
    ADD_FLOW_TO_LIST: add('listFlow'),
    UPDATE_FLOW_FROM_LIST: update('listFlow'),
    REMOVE_FLOW_FROM_LIST: removeByIds('listFlow'),

    SET_PAGINATION_FLOW: set('paginationFlow', {}),
    UPDATE_PAGINATION_FLOW_ADD(state) {
        state.paginationFlow.total++;
        if (state.listFlow.length > state.paginationFlow.pageSize) {
            state.listFlow.pop();
        }
    },
    UPDATE_PAGINATION_FLOW_DELETE(state, serviceLength) {
        const { paginationFlow } = state;
        paginationFlow.total -= serviceLength;
        if (paginationFlow.page !== 1) {
            const totalPagesCurrent = Math.ceil(paginationFlow.total / paginationFlow.pageSize);
            if (totalPagesCurrent < paginationFlow.page) {
                paginationFlow.page = totalPagesCurrent;
            }
        }
    },
    // flow-folder
    SET_LIST_FLOW_FOLDER: set('listFlowFolder', []),
    ADD_FLOW_FOLDER_TO_LIST: add('listFlowFolder'),
    UPDATE_FLOW_FOLDER_FROM_LIST: update('listFlowFolder'),
    REMOVE_FLOW_FOLDER_FROM_LIST: removeByIds('listFlowFolder'),
    SET_PAGINATION_FLOW_FOLDER: set('paginationFlowFolder', {}),
    UPDATE_PAGINATION_FLOW_FOLDER_ADD(state) {
        state.paginationFlowFolder.total++;
        if (state.listFlowFolder.length > state.paginationFlowFolder.pageSize) {
            state.listFlowFolder.pop();
        }
    },
    UPDATE_PAGINATION_FLOW_FOLDER_DELETE(state, serviceLength) {
        const { paginationFlowFolder } = state;
        paginationFlowFolder.total -= serviceLength;
        if (paginationFlowFolder.page !== 1) {
            const totalPagesCurrent = Math.ceil(paginationFlowFolder.total / paginationFlowFolder.pageSize);
            if (totalPagesCurrent < paginationFlowFolder.page) {
                paginationFlowFolder.page = totalPagesCurrent;
            }
        }
    },

    ADD_CHILD_TO_MENU_FLOWS(state, child) {
        state.listMenuItem[0].children.push({ id: child.id, name: child.name });
    },

    // sidebar filter flow && breadcrumb
    SET_LIST_FOLDER_TO_MENUS(state, folders) {
        state.listMenuItem[0].children = [];
        if (folders.length > 0) {
            folders.forEach((element) => {
                state.listMenuItem[0].children.push(element);
            });
        } else {
            state.listMenuItem[0].children = [];
        }
    },
    ADD_FOLDER_TO_MENUS(state, folder) {
        state.listMenuItem[0].children.unshift(folder);
    },
    UPDATE_FOLDER_FROM_MENUS(state, folder) {
        const index = state.listMenuItem[0].children.findIndex((e) => e.id === folder.id);
        state.listMenuItem[0].children[index].name = folder.name;
    },
    REMOVE_FOLDER_FROM_MENUS(state, id) {
        const index = state.listMenuItem[0].children.findIndex((e) => e.id === id);
        state.listMenuItem[0].children.splice(index, 1);
    },

    SET_BREADCRUMB(state, name) {
        if (name === 'flow') {
            state.breadCrumb = [];
            state.breadCrumb.push(state.listMenuItem[0].name);
        } else if (Number.isInteger(name)) {
            state.breadCrumb = [];
            const indexChild = state.listMenuItem[0].children.findIndex((c) => c.id === name);
            state.breadCrumb.push('Quản lý kịch bản');
            state.breadCrumb.push(state.listMenuItem[0].children[indexChild].name);
        } else {
            state.breadCrumb = [];
            const index = state.listMenuItem.findIndex((m) => m.id === name);
            state.breadCrumb.push(state.listMenuItem[index].name);
        }
    },
    SET_CURRENT_OPTION(state, value) {
        state.currentOption = value;
    },
    SET_CURRENT_FLOW(state, data) {
        data.blocks = data.blocks || [];
        data.blocks.map((block) => (block.cards = block.cards || []));

        // Divide listBlock and listBlockCard
        const listBlock = data.blocks;
        listBlock.forEach((block) => {
            block.cards = block.cards || [];
            block.cards.sort((a, b) => a.order - b.order);
        });
        const listBlockDraft = data.draft_blocks || [];
        listBlockDraft.forEach((block) => {
            block.cards = block.draft_cards || [];
            block.cards = block.cards.sort((a, b) => a.order - b.order);
        });

        // save to vuex
        if (state.builder.type === 'publish') {
            state.listBlock = listBlock;
        } else if (state.builder.type === 'draft') {
            state.listBlock = listBlockDraft;
        }
        state.currentFlow = data;
    },
    SET_SELECTED_BLOCK(state, value) {
        state.selectedBlock = value;
    },
    UPDATE_SELECTED_BLOCK(state, update) {
        Object.assign(state.selectedBlock, update);
    },
    SET_SELECTED_CARD(state, value) {
        state.selectedCard = value;
    },
    SET_DRAG_BLOCK_ID(state, value) {
        state.dragBlockId = value;
    },
    /**
     * Card
     */
    ADD_CARD(state, { block, card }) {
        const findBlock = state.listBlock.find((item) => item.id === block.id);
        if (findBlock) findBlock.cards.push(card);
    },


    UPDATE_CARD(state, { block, card }) {
        const findBlock = state.listBlock.find((item) => item.id === block.id);
        if (findBlock) {
            const index = findBlock.cards.find((item) => item.id === card.id);
            if (index >= 0) {
                // Vue.set(findBlock.cards, index, card);
                findBlock.cards[index] = card;
            }
        }
    },
    REMOVE_CARD(state, { block, cardId }) {
        const findBlock = state.listBlock.find((item) => item.id === block.id);
        if (findBlock) {
            const indexCard = findBlock.cards.findIndex((item) => item.id === cardId);
            if (indexCard >= 0) findBlock.cards.splice(indexCard, 1);
        }
    },
    /**
     * Card Draft
     */
    ADD_CARD_DRAFT(state, { block, card }) {
        const findBlock = state.listBlock.find((item) => item.id === block.id);
        if (findBlock && !findBlock.cards.find((c) => c.id === card.id)) {
            findBlock.draft_cards = findBlock.draft_cards || [];
            findBlock.draft_cards.push(card);
        }
        // update selected block if selected block === block
        if (state.selectedBlock.id === findBlock.id && !state.selectedBlock.cards.find((c) => c.id === card.id)) {
            state.selectedBlock.cards.push(card);
        }
    },
    UPDATE_CARD_DRAFT(state, { block, card }) {
        const findBlock = state.listBlock.find((item) => item.id === block.id);
        if (findBlock) {
            const index = findBlock.cards.findIndex((item) => item.id === card.id);
            if (index >= 0) {
                // Vue.set(findBlock.cards, index, card);
                findBlock.cards[index] = card;

                // const indexList = state.listBlock.findIndex((element) => element.id === findBlock.id);
                // state.listBlock[indexList].cards[index] = card;
            }
        }
        // update selectedblock if selected block === block
        if (state.selectedBlock && state.selectedBlock.id === block.id) {
            const index = state.selectedBlock.cards.findIndex((item) => item.id === card.id);
            // if (index >= 0) Vue.set(state.selectedBlock.cards, index, card);
            if (index >= 0) {
                state.selectedBlock.cards[index] = card;
            }
        }
    },
    REMOVE_CARD_DRAFT(state, { block, cardId }) {
        const findBlock = state.listBlock.find((item) => item.id === block.id);
        if (findBlock) {
            const indexCard = findBlock.cards.findIndex((item) => item.id === cardId);
            if (indexCard >= 0) findBlock.cards.splice(indexCard, 1);
        }
        // remove card in selectedBlock if selectedblock === block
        if (state.selectedBlock.id === block.id) {
            const indexCard = state.selectedBlock.cards.findIndex((item) => item.id === cardId);
            if (indexCard >= 0) state.selectedBlock.cards.splice(indexCard, 1);
        }
    },
    SET_NAME_FLOW(state, { name }) {
        state.currentFlow.name = name;
    },
};

const actions = {
    // Flow
    async getCurrentFlow({ commit }, { code }) {
        const flow = await axios.get(`/flows/${code}`, {
            params: {
                includes: 'blocks,cards',
            },
        });
        commit('SET_CURRENT_FLOW', flow.data);
    },
    async createFlow({ state, commit, rootState }, newflow) {
        const res = { isSuccess: false };
        try {
            newflow.bot_id = rootState.bot.currentBot.id;
            const { data } = await axios.post(state.api.flow, newflow);
            if (data) {
                commit('ADD_FLOW_TO_LIST', { newEl: data, addToTop: true });
                commit('UPDATE_PAGINATION_FLOW_ADD');
                res.isSuccess = true;
                return data;
            }
        } catch (err) {
            console.log('createFlow', err);
            return null;
        }
    },
    async cloneFlow() {},
    async updateFlow({ state, commit, rootState }, newflow) {
        const res = { isSuccess: false };
        try {
            newflow.bot_id = rootState.bot.currentBot.id;
            newflow.list_bot = ['fake_data'];
            const data = await axios.put(`${state.api.flow}/update-flow/${newflow.id}`, newflow);
            if (data) {
                commit('UPDATE_FLOW_FROM_LIST', { value: data });
                commit('SET_NAME_FLOW', { name: newflow.name });
                res.isSuccess = true;
            }
        } catch ({ response }) {
            if (response.status == 422) {
                return { ...res, msg: response.data?.errors?.name };
            }
            console.log('updateFlow', response);
        }
        return res;
    },
    async deleteFlow({ state, commit }, { flow_id }) {
        const res = { isSuccess: false };
        try {
            const {
                data: { isSuccess },
            } = await axios.delete(`${state.api.flow}/delete/${flow_id}`);
            if (isSuccess) {
                // commit('REMOVE_FLOW_FROM_LIST', [id]);
                commit('UPDATE_PAGINATION_FLOW_DELETE', 1);
                res.isSuccess = true;
            }
        } catch (err) {
            console.log('deleteFlow', err);
        }
        return res;
    },
    async publishFlow({ state, dispatch }) {
        const res = { isSuccess: false };
        try {
            const { code } = state.currentFlow;
            const { data } = await axios.put(`${state.api.flow}/${code}/publish/p`, {});
            if (data && data?.isSuccess) {
                res.isSuccess = true;
                dispatch('getCurrentFlow', { code: state.currentFlow.code });
            }
            return res;
        } catch (err) {
            console.log('updateFlowFolder', err);
        }
        return res;
    },

    // Block
    async createBlock({ state, commit }, newBlock) {
        const res = { isSuccess: false };
        try {
            const { data } = await axios.post(state.api.block, newBlock);
            if (data) {
                data.cards = data.draft_cards;
                commit('ADD_BLOCK', { item: data });
                res.id = data.id;
                res.isSuccess = true;
            }
        } catch (err) {
            console.log('createBlock', err);
        }
        return res;
    },

    async updateBlock({ state, commit, dispatch }, block) {
        const res = { isSuccess: false };
        try {
            block = cloneDeep(block);
            // If set is_start => update old start block => false
            if (block.is_start) {
                let oldStartBlock = state.currentFlow.draft_blocks.find((e) => e.is_start);
                if (oldStartBlock && oldStartBlock.id !== block.id) {
                    oldStartBlock = cloneDeep(oldStartBlock);
                    oldStartBlock.is_start = false;
                    dispatch('updateBlock', oldStartBlock);
                }
            }

            const { data } = await axios.put(`${state.api.block}/${block.id}`, block);

            if (data) {
                data.cards = block.cards;
                data.draft_cards = block.draft_cards;
                commit('UPDATE_BLOCK', { item: data, compare: 'id' });
                if (state.selectedBlock && data.id === state.selectedBlock.id) {
                    commit('SET_SELECTED_BLOCK', data);
                }
                res.isSuccess = true;
            }
        } catch (err) {
            console.log('updateBlock', err);
        }
        return res;
    },

    async cloneBlock({ state, commit }, { blockId, position }) {
        const res = { isSuccess: false };
        try {
            const { data } = await axios.post(`${state.api.block}/${blockId}/clone`, {
                position,
            });
            if (data) {
                data.cards = data.cards || [];
                commit('ADD_BLOCK', { item: data });
                res.isSuccess = true;
            }
        } catch (err) {
            console.log('cloneBlock', err);
        }
        return res;
    },

    async deleteBlock({ state, commit }, { blockId }) {
        const res = { isSuccess: false };
        try {
            const { data } = await axios.delete(`${state.api.block}/${blockId}`);
            if (data && data?.success) {
                commit('REMOVE_BLOCK', {
                    item: { id: blockId },
                    compare: 'id',
                });
                if (blockId === state.selectedBlock?.id) {
                    commit('SET_SELECTED_BLOCK', null);
                }
                res.isSuccess = true;
            }
        } catch (err) {
            console.log('deleteBlock', err);
        }
        return res;
    },

    // Card
    async createCard({ state, commit }, { block, newCard }) {
        const res = { isSuccess: false };
        try {
            newCard.flow_id = block.flow_id;
            newCard.block_id = block.id;

            const { data } = await axios.post(state.api.card, newCard);
            if (data) {
                commit('ADD_CARD_DRAFT', { block, card: data });
                res.isSuccess = true;
                res.card = data;
            }
        } catch (err) {
            console.log('createCard', err);
        }
        return res;
    },

    // async updateCard({ state, commit }, { block, card }) {
    async updateCard(context, { block, card }) {
        const res = { isSuccess: false };
        context.commit('SET_PENDING_SAVE_CARD', true);
        try {
            card = cloneDeep(card);
            const { data } = await axios.put(`${context.state.api.card}/${card.id}`, card);
            if (data) {
                context.commit('UPDATE_CARD_DRAFT', { block, card: data });
                res.isSuccess = true;

                context.dispatch('getCurrentFlow', { code: context.state.currentFlow.code });
            }
        } catch (err) {
            console.log('updateCard', err);
        }
        context.commit('SET_PENDING_SAVE_CARD', false);
        return res;
    },

    async updateCardOrder({ block, card }) {
        console.log('~~~~~~~~~~ update card ~~~~~~~~~~~~~');
        console.log({ block, card });
    },
    async deleteCard({ state, commit }, { block, cardId }) {
        console.log('~~~~~~~~~~ delete card ~~~~~~~~~~~~~');
        console.log({ block, cardId });
        const { data } = await axios.delete(`${state.api.card}/${cardId}`);
        if (data.success) {
            if (block.is_draft) {
                commit('REMOVE_CARD_DRAFT', { block, cardId });
            } else {
                commit('REMOVE_CARD', { block, cardId });
            }
        }
    },
    pushEvent({ dispatch }, event) {
        if (event.rightNow) {
            switch (event.event_type) {
                case 'update-card':
                    dispatch('updateCard', event.value);
                    break;
                case 'drag-block':
                    dispatch('updateBlock', event.value);
                    break;
            }
        } else {
            window.$nuxt.$emit('pushEvent', event);
        }
    },

    swapCard({ state, commit, dispatch }, { block, from, to }) {
        if ([from, to].some((e) => e < 0 || e > block.cards.length - 1)) return;
        const fromItem = block.cards[from];
        const toItem = block.cards[to];
        fromItem.order = to;
        toItem.order = from;
        block.cards.sort((a, b) => a.order - b.order);
        if (block.id === state.selectedBlock.id) {
            commit('UPDATE_SELECTED_BLOCK', { cards: block.cards });
        }
        // dispatch('updateCardOrder', { block, card: fromItem })
        commit('UPDATE_BLOCK', { item: block, compare: 'id' });
        return Promise.all([
            dispatch('updateCard', { block, card: fromItem }),
            dispatch('updateCard', { block, card: toItem }),
        ]);
    },

    async getListFlow({ state, commit, rootState }, { page, breadcrumb = 'flow', currentOption = '', pageSize }) {
        try {
            const { data } = await axios.get(state.api.flow, {
                params: {
                    'query[bot_id]': rootState.bot.currentBot.id,
                    populate: 'blocks,draft_blocks',
                    page: page || 1,
                    pageSize: pageSize || state.paginationFlow.pageSize,
                },
            });
            if (data) {
                const defaultFlow = [
                    rootState.bot.currentBot.default_flow_code,
                    rootState.bot.currentBot.welcome_flow_code,
                ];
                commit(
                    'SET_LIST_FLOW',
                    data.rows.filter((f) => !defaultFlow.includes(f.code))
                );
                commit('SET_BREADCRUMB', breadcrumb);
                commit('SET_CURRENT_OPTION', currentOption);
                commit('SET_PAGINATION_FLOW', data);
            }
        } catch (err) {
            console.log('getListFlow', err);
        }
    },

    async searchFlow({ state, commit }, { page, filter }) {
        try {
            const data = await axios.get(state.api.flow, {
                params: {
                    ...page,
                    ...filter,
                },
            });
            if (data) {
                commit('SET_LIST_FLOW', data.rows);
                commit('SET_PAGINATION_FLOW', data);
                return data;
            }
        } catch (err) {
            console.log('getListFlow', err);
        }
    },
};

const getters = {};

export default  {
    namespaced: true,
    state: defaultState,
    getters,
    actions,
    mutations,
};
