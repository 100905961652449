<template>
  <el-dialog v-model="isOpen" :title="title" :width="width">
    <slot />

    <template #footer>
      <span v-if="footer" class="dialog-footer">
        <el-button @click="handleClick(false)">{{ txtCancel }}</el-button>
        <el-button type="primary" @click="handleClick(true)">{{ txtConfirm }}</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import emitter from 'tiny-emitter/instance'

export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    width: {
      type: String,
      default: '40%',
    },
    footer: {
      type: Boolean,
      default: false,
    },
    txtCancel: {
      type: String,
      default: 'Cancel',
    },
    txtConfirm: {
      type: String,
      default: 'Confirm',
    },
  },
  emits: ['on-done'],
  data() {
    return {
      isOpen: false,
    }
  },
  watch: {
    isOpen: function (val) {
      if (val) {
        this.$emit('on-done')
      }
    },
  },
  mounted() {
    let self = this

    emitter.on('open-modal', function ({ name }) {
      self.name == name && (self.isOpen = true)
    })

    emitter.on('close-modal', function ({ name, onDone }) {
      self.name == name && (self.isOpen = false)
      typeof onDone == 'function' && onDone()
    })

    emitter.on('toggle-modal', function ({ name }) {
      self.name == name && (self.isOpen = !self.isOpen)
    })
  },
  methods: {
    handleClick(status) {
      if (!this.footer) return
      this.$emit(status ? 'submit' : 'cancel')
      this.isOpen = false
    },
  },
}
</script>
<style lang="css">
.el-dialog__body {
  padding-top: 10px;
  padding-bottom: 10px;
}
</style>
