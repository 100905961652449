const defaultState = {
    currentForm: {},
};

const actions = {};

const mutations = {
    SET_CURRENT_FORM(state, value) {
        state.currentForm = { ...value };
    },
};

const getters = {};
export default {
    namespaced: true,
    state: defaultState,
    getters,
    actions,
    mutations,
};
