import axios from 'axios';
import { objectToQueryString } from '../Helper';
class AxiosService {
    constructor() {
        axios.defaults.withCredentials = true;
        axios.defaults.headers.common['Accept'] = 'application/json';
        axios.defaults.headers.common['Content-Type'] = 'application/json';
        axios.defaults.headers.common['lang'] = window.locale;
        axios.defaults.headers.common['timezone'] = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const instance = axios.create({
            baseURL: '/api',
        });
        instance.interceptors.response.use(this.handleSuccess, this.handleError);
        this.instance = instance;
    }
    handleSuccess(response) {
        return response;
    }
    handleError(error) {
        if (error.response.status == 500) {
            alert('Something went wrong !!!\nServer error with status code: ' + error.response.status);
        }
        return Promise.reject(error);
    }
    get(url, query = { params: {} }) {
        return this.instance.get(`${url}?${objectToQueryString(query.params)}`);
    }
    post(url, body) {
        return this.instance.post(url, body);
    }
    put(url, body) {
        return this.instance.put(url, body);
    }
    delete(url) {
        return this.instance.delete(url);
    }
}
export default new AxiosService();
